import React, { useState, useRef, useCallback } from 'react';
import MaskEditor from './MaskEditor';
import {
	Box,
	Center,
	FormControl,
	FormLabel,
	IconButton,
	Text,
	VStack,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Icon,
	HStack,
	ButtonGroup,
	Button,
	Card,
	CardBody,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import { DragHandleIcon, EditIcon } from '@chakra-ui/icons';
import GenericSliderControl from './GenericSlider';

const InpaintingTab = ({
	uploadedImage,
	onImageUpload,
	onClearImage,
	isDragging,
	handleDragEnter,
	handleDragOver,
	handleDragLeave,
	handleDrop,
	uploadedImageWidth,
	uploadedImageHeight,
	fileInputRef,
	MAX_IMAGE_SIZE_MB,
	onMaskChange,
	denoiseStrength,
	setDenoiseStrength,
	denoiseInputValue,
	setDenoiseInputValue,
}) => {
	const [cursorSize, setCursorSize] = useState(20);
	const [scale, setScale] = useState(1);
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [isDraggingCanvas, setIsDraggingCanvas] = useState(false);
	const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
	const [mode, setMode] = useState('draw');
	const [isEraseAllDialogOpen, setIsEraseAllDialogOpen] = useState(false);
	const cancelRef = useRef();
	const canvasRef = useRef(null);
	const containerRef = useRef(null);

	const handleCursorSizeChange = useCallback((size) => {
		setCursorSize(Math.max(1, Math.min(100, size)));
	}, []);

	const handleBoxClick = (e) => {
		if (!uploadedImage) {
			fileInputRef.current?.click();
		}
	};

	const handleZoomChange = (value) => {
		setScale(value);
	};

	const handleMouseDown = (e) => {
		if (mode === 'pan') {
			setIsDraggingCanvas(true);
			setDragStart({
				x: e.clientX - position.x,
				y: e.clientY - position.y
			});
		}
	};

	const handleMouseMove = (e) => {
		if (isDraggingCanvas && mode === 'pan') {
			setPosition({
				x: e.clientX - dragStart.x,
				y: e.clientY - dragStart.y
			});
		}
	};

	const handleMouseUp = () => {
		setIsDraggingCanvas(false);
	};

	const resetView = () => {
		setScale(1);
		setPosition({ x: 0, y: 0 });
	};

	const handleEraseAll = () => {
		if (canvasRef.current && canvasRef.current.clearMask) {
			canvasRef.current.clearMask();
			onMaskChange(null);
		}
		setIsEraseAllDialogOpen(false);
	};

	const handleTouchStart = (e) => {
		e.preventDefault();
		if (mode === 'pan') {
			setIsDraggingCanvas(true);
			const touch = e.touches[0];
			setDragStart({
				x: touch.clientX - position.x,
				y: touch.clientY - position.y
			});
		}
	};

	const handleTouchMove = (e) => {
		e.preventDefault();
		if (isDraggingCanvas && mode === 'pan') {
			const touch = e.touches[0];
			setPosition({
				x: touch.clientX - dragStart.x,
				y: touch.clientY - dragStart.y
			});
		}
	};

	const handleTouchEnd = () => {
		setIsDraggingCanvas(false);
	};

	return (
		<VStack spacing={4} width="100%" align="stretch">
			<FormControl>
				<FormLabel>Upload Image for Inpainting (Max {MAX_IMAGE_SIZE_MB}MB)</FormLabel>
				<input
					type="file"
					accept="image/*"
					onChange={onImageUpload}
					ref={fileInputRef}
					style={{ display: 'none' }}
				/>

				{/* Image Upload Box */}
				<Box
					border="2px dashed"
					borderColor={isDragging ? "blue.500" : "gray.300"}
					borderRadius="md"
					textAlign="center"
					cursor={uploadedImage
						? (mode === 'pan'
							? "move"
							: mode === 'erase'
								? "crosshair"
								: "crosshair")
						: "pointer"}
					onClick={handleBoxClick}
					onDragEnter={handleDragEnter}
					onDragOver={handleDragOver}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
					bg={isDragging ? "blue.50" : "black"}
					transition="all 0.2s"
					position="relative"
					height="600px"
					overflow="hidden"
					ref={containerRef}
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					onMouseLeave={handleMouseUp}
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
					onTouchCancel={handleTouchEnd}
					style={{
						touchAction: 'none'
					}}
				>
					{uploadedImage ? (
						<Box position="relative" height="100%" width="100%">
							<Box
								position="absolute"
								style={{
									transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
									transformOrigin: 'center',
									transition: isDraggingCanvas ? 'none' : 'transform 0.1s'
								}}
							>
								<MaskEditor
									src={uploadedImage}
									canvasRef={canvasRef}
									cursorSize={cursorSize}
									drawMode={mode === 'erase' ? 'erase' : 'draw'}
									width={uploadedImageWidth || 600}
									height={uploadedImageHeight || 600}
									onMaskChange={onMaskChange}
									scale={scale}
								/>
								{mode === 'pan' && (
									<Box
										position="absolute"
										top={0}
										left={0}
										right={0}
										bottom={0}
										cursor="move"
										zIndex={1}
									/>
								)}
							</Box>

							<IconButton
								icon={<CloseIcon />}
								position="absolute"
								top={2}
								right={2}
								size="sm"
								onClick={(e) => {
									e.stopPropagation();
									onClearImage();
								}}
								aria-label="Remove image"
								zIndex={2}
							/>
						</Box>
					) : (
						<Center height="100%">
							<VStack spacing={2}>
								<Icon as={AddIcon} w={6} h={6} />
								<Text>Drop an image here or click to upload</Text>
								<Text fontSize="sm" color="gray.500">
									(Max {MAX_IMAGE_SIZE_MB}MB)
								</Text>
							</VStack>
						</Center>
					)}
				</Box>
			</FormControl>

			{uploadedImage && (
				<Card mb={4} variant="outline">
					<CardBody>
						<VStack spacing={4}>
							{/* Mode toggle buttons */}
							<ButtonGroup isAttached variant="outline" width="100%">
								<Button
									leftIcon={<EditIcon />}
									onClick={() => setMode('draw')}
									colorScheme={mode === 'draw' ? 'blue' : 'gray'}
									flex={1}
								>
									Draw
								</Button>
								<Button
									leftIcon={<CloseIcon />}
									onClick={() => setMode('erase')}
									colorScheme={mode === 'erase' ? 'blue' : 'gray'}
									flex={1}
								>
									Erase
								</Button>
								<Button
									leftIcon={<DragHandleIcon />}
									onClick={() => setMode('pan')}
									colorScheme={mode === 'pan' ? 'blue' : 'gray'}
									flex={1}
								>
									Pan
								</Button>
							</ButtonGroup>

							{/* Brush size slider */}
							<HStack width="100%" spacing={4}>
								<Text fontSize="sm" whiteSpace="nowrap">Brush Size:</Text>
								<Slider
									value={cursorSize}
									onChange={handleCursorSizeChange}
									min={1}
									max={100}
									step={1}
									flex={1}
								>
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<SliderThumb />
								</Slider>
								<Text fontSize="sm" width="40px">
									{cursorSize}
								</Text>
							</HStack>

							{/* Zoom slider */}
							<HStack width="100%" spacing={4}>
								<Text fontSize="sm" whiteSpace="nowrap">Zoom:</Text>
								<Slider
									value={scale}
									onChange={handleZoomChange}
									min={0.1}
									max={3}
									step={0.01}
									flex={1}
								>
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<SliderThumb />
								</Slider>
								<Text fontSize="sm" width="60px">
									{(scale * 100).toFixed(0)}%
								</Text>
							</HStack>

							<HStack width="100%" justify="flex-end" spacing={4}>
								<Button size="xs" onClick={() => setIsEraseAllDialogOpen(true)} colorScheme="red">
									Erase All
								</Button>
								<Button size="xs" onClick={resetView}>
									Reset Zoom
								</Button>
							</HStack>
						</VStack>
					</CardBody>
				</Card>
			)}

			<AlertDialog
				isOpen={isEraseAllDialogOpen}
				leastDestructiveRef={cancelRef}
				onClose={() => setIsEraseAllDialogOpen(false)}
				isCentered
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Erase All Mask
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure you want to erase all? This action cannot be undone.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={() => setIsEraseAllDialogOpen(false)}>
								No
							</Button>
							<Button colorScheme="red" onClick={handleEraseAll} ml={3}>
								Yes
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<GenericSliderControl
				value={denoiseStrength}
				setValue={setDenoiseStrength}
				inputValue={denoiseInputValue}
				setInputValue={setDenoiseInputValue}
				label="Denoise Strength"
				tooltipText="The denoise strength is used to determine how close the generated image should be to the inputted image. A lower denoise will make the output more similar to the input."
				min={0}
				max={1}
				step={0.01}
				leftLabel="Less different"
				rightLabel="More different"
			/>
		</VStack>
	);
};

export default InpaintingTab;