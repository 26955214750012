import React from 'react';
import {
	FormControl,
	FormLabel,
	HStack,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Box,
	Text,
	Slider,
	Tooltip,
	IconButton,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

const GenericSliderControl = ({
	value,
	setValue,
	inputValue,
	setInputValue,
	label,
	tooltipText,
	min,
	max,
	step,
	leftLabel,
	rightLabel
}) => {
	return (
		<FormControl>
			<FormLabel>
				<HStack spacing={1} alignItems="center">
					<Text>{label}</Text>
					<Tooltip label={tooltipText} hasArrow>
						<IconButton
							icon={<InfoIcon />}
							size="xs"
							variant="ghost"
							aria-label={`${label} information`}
						/>
					</Tooltip>
				</HStack>
			</FormLabel>
			<HStack spacing={4}>
				<NumberInput
					maxW="120px"
					mr="2rem"
					value={inputValue}
					onChange={(valueString) => {
						setInputValue(valueString);
					}}
					step={step}
					min={min}
					max={max}
					onBlur={() => {
						const numValue = Number(inputValue);
						if (!isNaN(numValue)) {
							const clampedValue = Math.min(Math.max(numValue, min), max);
							const formattedValue = clampedValue.toFixed(2);
							setValue(Number(formattedValue));
							setInputValue(formattedValue);
						} else {
							setInputValue(value.toFixed(2));
						}
					}}
				>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper
							onClick={() => {
								const newValue = Math.min(value + step, max);
								setValue(newValue);
							}}
						/>
						<NumberDecrementStepper
							onClick={() => {
								const newValue = Math.max(value - step, min);
								setValue(newValue);
							}}
						/>
					</NumberInputStepper>
				</NumberInput>
				<Box position="relative" flex="1" pt={6}>
					<Slider
						value={value}
						onChange={(v) => {
							setValue(v);
							setInputValue(v.toFixed(2));
						}}
						min={min}
						max={max}
						step={step}
					>
						<SliderTrack>
							<SliderFilledTrack />
						</SliderTrack>
						<SliderThumb />
					</Slider>
					{leftLabel && (
						<Text position="absolute" left={0} top={0} fontSize="sm" color="gray.500">
							{leftLabel}
						</Text>
					)}
					{rightLabel && (
						<Text position="absolute" right={0} top={0} fontSize="sm" color="gray.500">
							{rightLabel}
						</Text>
					)}
				</Box>
			</HStack>
		</FormControl>
	);
};

export default GenericSliderControl;